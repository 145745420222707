import { Flex } from "antd"
import './Revolution.css';
import { useIntl } from "react-intl";
const Revolution = () => {
    const intl = useIntl();
    return (
        <Flex className="revolution" align="end">
            <div className="revolution_info font_tech">
                <Flex style={{ width: '100%', justifyContent: 'start' }}>
                    <span>{intl.formatMessage({ id: 'revolution_1' })}</span>
                </Flex>
                <Flex style={{ width: '100%', justifyContent: 'end' }}>
                    <span>{intl.formatMessage({ id: 'revolution_2' })}</span>
                </Flex>
            </div>
        </Flex>
    )
}

export default Revolution;