import { Flex } from 'antd';
import './Progressive.css';
import { useIntl } from 'react-intl';
import Step from './Step';

const Progressive = () => {
    const intl = useIntl();
    return (
        <>
            <Flex vertical gap={5} className='progressive_mobile' align='center'>
                <p className='progressiveTitle'>{intl.formatMessage({ id: 'progressiveTitle' })}</p>
                <div className='progressive_no_border'>
                    <p className='foundHistory' >{intl.formatMessage({ id: 'progressive_1' })}</p>
                </div>
                <div className='progressive_no_border'>
                    <p className='foundHistory'>{intl.formatMessage({ id: 'progressive_2' })}</p>
                </div>
                <Flex wrap={'wrap'} justify={'space-evenly'}>
                    <Step idx={1} title={intl.formatMessage({ id: 'step_1_title' })} content={intl.formatMessage({ id: 'step_1_content' })} />
                    <Step idx={2} title={intl.formatMessage({ id: 'step_2_title' })} content={intl.formatMessage({ id: 'step_2_content' })} />
                    <Step idx={3} title={intl.formatMessage({ id: 'step_3_title' })} content={intl.formatMessage({ id: 'step_3_content' })} />
                    <Step idx={4} title={intl.formatMessage({ id: 'step_4_title' })} content={intl.formatMessage({ id: 'step_4_content' })} />
                </Flex>

            </Flex>
            <Flex vertical gap={5} className='progressive_pc' style={{ paddingTop: '100px', paddingBottom: '100px' }}>
                <p className='progressiveTitle'>{intl.formatMessage({ id: 'progressiveTitle' })}</p>
                <div className='progressive_no_border'>
                    <p className='foundHistory' >{intl.formatMessage({ id: 'progressive_1' })}</p>
                </div>
                <div className='progressive_no_border '>
                    <p className='foundHistory'>{intl.formatMessage({ id: 'progressive_2' })}</p>
                </div>
                <Flex wrap justify={'space-evenly'}>
                    <Step idx={1} title={intl.formatMessage({ id: 'step_1_title' })} content={intl.formatMessage({ id: 'step_1_content' })} />
                    <Step idx={2} title={intl.formatMessage({ id: 'step_2_title' })} content={intl.formatMessage({ id: 'step_2_content' })} />
                    <Step idx={3} title={intl.formatMessage({ id: 'step_3_title' })} content={intl.formatMessage({ id: 'step_3_content' })} />
                    <Step idx={4} title={intl.formatMessage({ id: 'step_4_title' })} content={intl.formatMessage({ id: 'step_4_content' })} />
                </Flex>
            </Flex>
        </>
    )
}
export default Progressive;