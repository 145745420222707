import { Col, Flex, Row } from 'antd';
import './Future.css';
import { useIntl } from 'react-intl';

const Future = () => {
    const intl = useIntl();
    return (
        <>
            <Flex vertical gap={5} className='future_mobile' align='center'>
                <p className='futureTitle'>{intl.formatMessage({ id: 'futureTitle' })}</p>
                <div className='future_left_grey_border'>
                    <img alt='founders' src='/assets/global.png' style={{
                        objectFit: 'cover', width: '60%',
                        marginLeft: '20%', marginRight: '20%'
                    }} />
                </div>
                <div className='future_no_border'>
                    <p className='foundHistory' >{intl.formatMessage({ id: 'future_1' })}</p>
                </div>


            </Flex>
            <Flex vertical gap={5} className='future_pc' style={{ paddingTop: '100px', paddingBottom: '100px' }}>
                <p className='futureTitle'>{intl.formatMessage({ id: 'futureTitle' })}</p>
                <Row gutter={0}>
                    <Col span={12}>
                        <div className='future_left_grey_border'>
                            <p className='foundHistory' style={{ paddingLeft: 60, lineHeight:1.5 }} >{intl.formatMessage({ id: 'future_1' })}</p>
                        </div>

                    </Col>
                    <Col span={12} >
                        <div className='future_right_grey_border'>
                            <img alt='founders' src='/assets/global.png' style={{
                                objectFit: 'cover', width: '400px', paddingLeft: '100px'
                            }} />
                        </div>
                    </Col>
                </Row>
            </Flex>
        </>
    )
}
export default Future;