import { Col, Flex, Row } from 'antd';
import { useIntl } from 'react-intl';
import './Footer.css';
const Footer = () => {
    const intl = useIntl();
    return (
        <>
            <Row style={{ backgroundColor: 'grey', minHeight: '200px', padding: 40 }}>
                <Col span={24} md={{ span: 8 }}>
                    <img src='/assets/aisidao-gold.png' alt='' style={{ width: 80 }} />
                </Col>
                <Col span={12} md={{ span: 8 }} style={{ marginTop: 30 }}>
                    <Flex vertical gap={10}>
                        <a className='footer_link' href='https://x.com/aisi_dao?s=21' target='_blank'  rel="noreferrer">{intl.formatMessage({ id: 'footer_link_1' })}</a>
                        <a className='footer_link' href='https://twitter.com/aisidao_' target='_blank'  rel="noreferrer">{intl.formatMessage({ id: 'footer_link_2' })}</a>
                        <a className='footer_link' href='https://t.me/aisidao/1' target='_blank'  rel="noreferrer">{intl.formatMessage({ id: 'footer_link_3' })}</a>
                        <a className='footer_link' href='https://t.me/AiSiDaos' target='_blank'  rel="noreferrer">{intl.formatMessage({ id: 'footer_link_4' })}</a>

                    </Flex>
                </Col>
                <Col span={12} md={{ span: 8 }} style={{ marginTop: 30 }}>
                    <Flex vertical gap={10}>
                        <a className='footer_link' href='https://www.btctool.pro/brc20/aisi' target='_blank'  rel="noreferrer">
                            {intl.formatMessage({ id: 'footer_link_5' })}
                        </a>
                        <a className='footer_link' href='https://t.me/aisidao/3401' target='_blank'  rel="noreferrer">
                            {intl.formatMessage({ id: 'footer_link_6' })}
                        </a>
                        <a className='footer_link' href='https://www.okx.com/cn/web3/marketplace/ordinals/brc20/aisi' target='_blank'  rel="noreferrer">
                            {intl.formatMessage({ id: 'footer_link_7' })}
                        </a>
                        <a className='footer_link' href='https://unisat.io/market/brc20?tick=aisi&tab=1' target='_blank'  rel="noreferrer">
                            {intl.formatMessage({ id: 'footer_link_8' })}
                        </a>
                    </Flex>
                </Col>
            </Row>
        </>
    )
}
export default Footer;