import { Flex } from "antd"
import './Hero.css';
import { useIntl } from "react-intl";

const Hero = () => {
    const intl = useIntl();
    return (
        <Flex className="fullscreen_container hero_bg" vertical align={'center'} gap={20} justify="center">
            <div className="grey_cover" />
            <img src="/assets/aisidao-gold.png" className="hero_logo" alt="logo" />
            <span className="font_tech title">AISIDAO</span>
            <span className="font_tech hero_info">{intl.formatMessage({ id: 'hero_info' })}</span>

        </Flex>
    )
}

export default Hero;