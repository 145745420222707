import { Col, Flex, Row } from 'antd';
import './Found.css';
import { useIntl } from 'react-intl';

const Founder = () => {
    const intl = useIntl();
    return (
        <>
            <Flex vertical gap={5} className='mobile' align='center'>
                <p className='foundTitle'>{intl.formatMessage({ id: 'historyTitle' })}</p>
                <div className='contentWithBorder'>
                    <img alt='founders' src='/assets/founders.png' style={{ objectFit: 'cover', width: '100%' }} />
                    <p className='foundHistory' >{intl.formatMessage({ id: 'history_1' })}</p>
                    <p className='foundHistory'>{intl.formatMessage({ id: 'history_2' })}</p>
                    <p className='foundHistory'>{intl.formatMessage({ id: 'history_3' })}</p>
                </div>

            </Flex>
            <Flex vertical gap={5} className='pc' style={{paddingTop:'100px', paddingBottom:'100px'}}>
                <p className='foundTitle'>{intl.formatMessage({ id: 'historyTitle' })}</p>
                <div className='contentWithBorder'>
                    <Row gutter={20}>
                        <Col span={12}>
                            <p className='foundHistory' >{intl.formatMessage({ id: 'history_1' })}</p>
                            <p className='foundHistory'>{intl.formatMessage({ id: 'history_2' })}</p>
                            <p className='foundHistory'>{intl.formatMessage({ id: 'history_3' })}</p>
                        </Col>
                        <Col span={12}>
                            <img alt='founders' src='/assets/founders.png' style={{ objectFit: 'cover', width: '100%' }} />
                        </Col>
                    </Row>
                </div>
            </Flex>
        </>
    )
}
export default Founder;