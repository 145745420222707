import { Col, Flex, Row, Space } from 'antd';
import './Inscription.css';
import { useIntl } from 'react-intl';

const Inscription = () => {
    const intl = useIntl();
    return (
        <>
            <Flex vertical gap={5} className='inscription_mobile' align='center'>
                <p className='inscriptionTitle'>{intl.formatMessage({ id: 'inscriptionTitle' })}</p>
                <Space>
                    <Flex vertical gap={5} align='center'>
                        <img alt='founders' src='/assets/aisidao-gold.png' style={{
                            objectFit: 'cover', width: '100px',
                        }} />
                        <p className='font_tech' style={{ fontSize: 'medium' }} >{intl.formatMessage({ id: 'amount_1' })}</p>
                        <p className='font_tech' style={{ fontSize: 'medium' }} >{intl.formatMessage({ id: 'amount_2' })}</p>
                        <p className='font_tech' style={{ fontSize: 'medium' }} >{intl.formatMessage({ id: 'amount_3' })}</p>
                    </Flex>
                    <img alt='founders' src='/assets/airdrop.png' className='airdrop' style={{ width: 200 }} />

                </Space>
                <div className='inscription_no_border'>
                    <p className='foundHistory' >{intl.formatMessage({ id: 'inscription_1' })}</p>
                </div>
                <div className='inscription_no_border'>
                    <p className='foundHistory' >{intl.formatMessage({ id: 'inscription_2' })}</p>
                </div>

            </Flex>
            <Flex vertical gap={5} className='inscription_pc' style={{ paddingTop: '100px', paddingBottom: '100px' }}>
                <p className='inscriptionTitle'>{intl.formatMessage({ id: 'inscriptionTitle' })}</p>
                <Row gutter={0}>
                    <Col span={12}>
                        <div className='inscription_no_border'>
                            <p className='foundHistory' style={{ paddingLeft: 60, lineHeight: 1.5 }} >{intl.formatMessage({ id: 'inscription_1' })}</p>
                        </div>
                        <div className='inscription_left_gold_border'>
                            <p className='foundHistory' style={{ paddingLeft: 60, lineHeight: 1.5 }} >{intl.formatMessage({ id: 'inscription_2' })}</p>
                        </div>

                    </Col>
                    <Col span={12} >
                        <div className='inscription_right_grey_border'>
                            <Flex vertical gap={5} align='center'>
                                <img alt='founders' src='/assets/aisidao-gold.png' style={{
                                    objectFit: 'cover', width: '200px',
                                }} />
                                <p className='font_tech' style={{ fontSize: 'x-large' }} >{intl.formatMessage({ id: 'amount_1' })}</p>
                                <p className='font_tech' style={{ fontSize: 'x-large' }} >{intl.formatMessage({ id: 'amount_2' })}</p>
                                <p className='font_tech' style={{ fontSize: 'x-large' }} >{intl.formatMessage({ id: 'amount_3' })}</p>
                                <img alt='founders' src='/assets/airdrop.png' className='airdrop' />
                            </Flex>

                        </div>
                    </Col>
                </Row>
            </Flex>
        </>
    )
}
export default Inscription;