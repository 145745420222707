import { Col, Flex, Row } from 'antd';
import './Launch.css';
import { useIntl } from 'react-intl';

const Launch = () => {
    const intl = useIntl();
    return (
        <>
            <Flex vertical gap={5} className='launch_mobile' align='center'>
                <p className='launchTitle'>{intl.formatMessage({ id: 'launchTitle' })}</p>
                <div className='launch_content'>
                    <img alt='founders' src='/assets/people3.webp' style={{
                        objectFit: 'cover', width: '60%', borderRadius: '20px',
                        marginLeft: '20%', marginRight: '20%'
                    }} />
                    <p className='foundHistory' >{intl.formatMessage({ id: 'launch_1' })}</p>
                    <p className='foundHistory'>{intl.formatMessage({ id: 'launch_2' })}</p>
                </div>
            </Flex>
            <Flex vertical gap={5} className='launch_pc' style={{paddingTop:'100px', paddingBottom:'100px'}}>
                <p className='launchTitle'>{intl.formatMessage({ id: 'launchTitle' })}</p>
                <div className='launch_content'>
                    <Row gutter={20}>
                        <Col span={12}>
                            <p className='foundHistory' >{intl.formatMessage({ id: 'launch_1' })}</p>
                            <p className='foundHistory'>{intl.formatMessage({ id: 'launch_2' })}</p>
                        </Col>
                        <Col span={12} >
                            <img alt='founders' src='/assets/people3.webp' style={{
                                objectFit: 'cover', width: '400px',
                                borderRadius: '50px'
                            }} />
                        </Col>
                    </Row>
                </div>
            </Flex>
        </>
    )
}
export default Launch;