import { useEffect, useState } from 'react';
import './App.css';
import Nav from './component/Nav/Nav';
import en from './locale/en';
import zh from './locale/zh';
import { IntlProvider } from 'react-intl';
import Hero from './component/Hero/Hero';
import Revolution from './component/Revolution/Revolution';
import Founder from './component/Found/Found';
import Launch from './component/Launch/Launch';
import Valuable from './component/Valuable/Valuable';
import Progressive from './component/Progressive/Progressive';
import Future from './component/Future/Future';
import BackGound from './component/BackGound/BackGound';
import Inscription from './component/Inscription/Inscription';
import Footer from './component/Footer/Footer';

function App() {
  const [lang, setLang] = useState('en');
  const [message, setMessage] = useState(en);
  useEffect(() => {
    switch (lang) {
      case 'en':
        setMessage(en);
        break;
      case 'zh':
        setMessage(zh);
        break;
      default:
        setMessage(en);
    }
  }, [lang]);

  return (
    <IntlProvider locale={lang} messages={message}>
      <Nav setLang={setLang} />
      <Hero />
      <Revolution />
      <Founder />
      <Launch />
      <Valuable />
      <Progressive />
      <Future />
      <BackGound />
      <Inscription />
      <Footer />
    </IntlProvider>
  );
}

export default App;
