import { TranslationOutlined } from '@ant-design/icons'
import { Dropdown, Space, } from 'antd';
import { useIntl } from 'react-intl';
const LangSelect = (props) => {
    const { setLang } = props;
    const intl = useIntl();
    const setLanguage = (lang) => {
        setLang(lang);
    }
    const items = [
        {
            key: '1',
            label: (
                <span>English</span>
            ),
            onClick: () => { setLanguage('en') },

        },
        {
            key: '2',
            label: (
                <span>中文</span>
            ),
            onClick: () => { setLanguage('zh') },
        },

    ];
    return (
        <div >
            <Dropdown menu={{ items }}>
                <Space style={{ margin: '10px', cursor: 'pointer' }}>
                    <TranslationOutlined style={{ fontSize: '20px', color:'black' }} />
                    <span style={{ color: 'black' }}>{intl.formatMessage({ id: 'switchLang' })}</span>
                </Space>
            </Dropdown>
        </div>
    )
}

export default LangSelect;