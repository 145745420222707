const Step = (props) => {
    const { idx, title, content } = props;
    const ringColor = ['', 'darkgray', 'grey', '#4f4f4f', 'black']
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{
                width: 120, height: 120, borderRadius: 60, backgroundColor: ringColor[idx], display: 'flex',
                alignItems: 'center', justifyContent: 'center', zIndex: 2, position: 'relative',
            }}>
                <div style={{
                    width: 100, height: 100, borderRadius: 50, backgroundColor: '#d3ae75', display: 'flex', alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <p style={{ fontSize: 'xx-large', color: 'whitesmoke' }}>{idx}</p>
                </div>
            </div>
            <div style={{
                height: 200, width: 160, backgroundColor: 'whitesmoke', display: 'flex', flexDirection: 'column', justifyContent: 'center',
                alignItems: 'center', position: 'relative', top: '-30px', zIndex: 1, padding: '10px', gap: '10px'
            }}>
                <p className="font_tech" style={{ fontWeight: 'bold', fontSize: 'large' }}>{title}</p>
                <p className="font_tech" style={{ color: 'grey', textAlign: 'center', fontSize: idx === 4 ? 'small' : 'medium' }} >{content}</p>

            </div>
        </div>
    )
}

export default Step;