import { Col, Flex, Row } from 'antd';
import './Valuable.css';
import { useIntl } from 'react-intl';

const Valuable = () => {
    const intl = useIntl();
    return (
        <>
            <Flex vertical gap={5} className='valuable_mobile' align='center'>
                <p className='valuableTitle'>{intl.formatMessage({ id: 'valuableTitle' })}</p>
                <div className='valuable_left_grey_border'>
                    <img alt='founders' src='/assets/aisishining.png' style={{
                        objectFit: 'cover', width: '60%',
                        marginLeft: '20%', marginRight: '20%'
                    }} />
                </div>
                <div className='valuable_no_border'>
                    <p className='foundHistory' >{intl.formatMessage({ id: 'valuable_1' })}</p>
                </div>
                <div className='valuable_right_grey_border'>
                    <p className='foundHistory'>{intl.formatMessage({ id: 'valuable_2' })}</p>
                </div>

            </Flex>
            <Flex vertical gap={5} className='valuable_pc' style={{paddingTop:'100px', paddingBottom:'150px'}}>
                <p className='valuableTitle'>{intl.formatMessage({ id: 'valuableTitle' })}</p>
                <Row gutter={0}>
                    <Col span={12}>
                        <div className='valuable_no_border'>
                            <p className='foundHistory' >{intl.formatMessage({ id: 'valuable_1' })}</p>
                        </div>
                        <div className=' valuable_left_grey_border '>
                            <p className='foundHistory'>{intl.formatMessage({ id: 'valuable_2' })}</p>
                        </div>
                    </Col>
                    <Col span={12} >
                        <div className='valuable_right_grey_border'>
                            <img alt='founders' src='/assets/aisishining.png' style={{
                                objectFit: 'cover', width: '400px'
                            }} />
                        </div>
                    </Col>
                </Row>
            </Flex>
        </>
    )
}
export default Valuable;