import { Row, Space } from "antd";
import LangSelect from "../LangSelect";
import './Nav.css';

const Nav = (props) => {
    const { setLang } = props;
    console.log(setLang);
    return (
        <Row justify={'space-between'} className="nav">
            <Space>
                <img src="/assets/aisidao-gold.png" style={{ width: '40px', marginLeft: '5px' }} alt="logo" />
                <span className="font_tech" style={{ color: 'black', fontSize: 'large' }}>AISIDAO</span>
            </Space>
            <LangSelect setLang={setLang} />
        </Row >
    )
}
export default Nav;