import { Col, Flex, Row } from 'antd';
import './BackGound.css';
import { useIntl } from 'react-intl';

const BackGound = () => {
    const intl = useIntl();
    return (
        <>
            <Flex vertical gap={5} className='background_mobile' align='center'>
                <p className='backgroundTitle'>{intl.formatMessage({ id: 'backgroundTitle' })}</p>
                <div className='background_left_grey_border'>
                    <img alt='founders' src='/assets/brc20-3.png' style={{
                        objectFit: 'cover', width: '60%',
                        marginLeft: '20%', marginRight: '20%'
                    }} />
                </div>
                <div className='background_no_border'>
                    <p className='foundHistory' >{intl.formatMessage({ id: 'background_1' })}</p>
                </div>


            </Flex>
            <Flex vertical gap={5} className='background_pc'style={{ paddingTop: '100px', paddingBottom: '100px' }}>
                <p className='backgroundTitle'>{intl.formatMessage({ id: 'backgroundTitle' })}</p>
                <Row gutter={0}>
                    <Col span={12}>
                        <div className='background_left_gold_border'>
                            <p className='foundHistory' style={{ paddingLeft: 60, lineHeight: 1.5 }} >{intl.formatMessage({ id: 'background_1' })}</p>
                        </div>

                    </Col>
                    <Col span={12} >
                        <div className='background_right_grey_border'>
                            <img alt='founders' src='/assets/brc20-3.png' style={{
                                objectFit: 'cover', width: '550px', marginLeft: '100px', borderRadius: 30,
                            }} />
                        </div>
                    </Col>
                </Row>
            </Flex>
        </>
    )
}
export default BackGound;